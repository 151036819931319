import React from "react";
import { Container } from "../../components";
import {
  AppStoreDownload,
  Circle1,
  HandHoldingPhoneImage2,
  PlayStoreDownload,
} from "../../assets";

const Download = () => {
  return (
    <section className="px-6 py-12 lg:py-24">
      <Container className="bg-[#F8F9FD] rounded-2xl pl-8 pt-8 lg:pb-8 relative overflow-hidden lg:h-[538px] grid md:grid-cols-2 lg:items-center gap-10 lg:gap-20">
        <div className="relative z-[2]">
          <h3 className="text-4xl lg:text-6xl mb-6 pr-6 lg:pr-0">Be the first to know when we launch</h3>
          <p>
            Welcome to Meind, where we empower therapists by simplifying their
            service processes. Our mission is to maximize their profits while
            ensuring effective client care.
          </p>
          <div className="flex items-center gap-5 mt-6">
            <img src={AppStoreDownload} alt="download on the App Store" loading="lazy" />
            <img src={PlayStoreDownload} alt="download on Google Play" loading="lazy" />
          </div>
        </div>
        <div className="">
          <img
            className="relative lg:absolute z-[2] md:right-0 lg:right-20 bottom-0"
            src={HandHoldingPhoneImage2}
            alt="hand holding phone"
            loading="lazy"
          />
          <img
            className="absolute w-[180px] lg:w-[unset] h-[210px] md:h-[unset] right-0 top-0 z-[1]"
            src={Circle1}
            alt="placeholder"
            loading="lazy"
          />
        </div>
      </Container>
    </section>
  );
};

export default Download;
