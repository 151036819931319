import React from "react";
import { TabItem } from "./TabItem";
import {
  ClientTab1Image,
  ClientTab2Image,
  ClientTab3Image,
} from "../../../assets";

export const ClientTab = () => {
  return (
    <div className="grid gap-6 xl:grid-cols-2 mt-[44px]">
      <TabItem
        title="Easy Booking & Flexible Scheduling"
        description="Clients can book a massage at their convenience, choosing from various time slots and locations, making the process hassle-free."
        image={ClientTab1Image}
        className="grid-rows-[auto_1fr]"
        variant="client"
        centeredImage
        imgClassName="max-w-[500px] w-full"
      />
      <div className="grid gap-6">
        <TabItem
          className="lg:grid-cols-2 items-center"
          title="Seamless Payment System"
          description="Secure, quick payments integrated into the platform, allowing clients to pay easily before or after their session without needing to deal with cash."
          image={ClientTab2Image}
          variant="client"
          imgClassName="w-4/5 left-1/2 -translate-x-1/2"
        />
        <TabItem
          className="lg:grid-cols-2 items-center"
          title="Personalized Therapist Matching"
          description="With customized filters, clients can easily find therapists based on their preferences, ensuring they get the right fit for their needs."
          image={ClientTab3Image}
          variant="client"
          imgClassName="w-full"
        />
      </div>
    </div>
  );
};
