import React from "react";
import { TabItem } from "./TabItem";
import {
  TherapistTab1Image,
  TherapistTab2Image,
  TherapistTab3Image,
} from "../../../assets";

export const TherapistTab = () => {
  return (
    <div className="grid gap-6 lg:grid-cols-3 mt-[44px]">
      <TabItem
        title="Flexible Hours"
        description="Therapists can choose their work hours, set their availability, and accept bookings accpording to their schedule, offering them freedom to work when they want."
        image={TherapistTab1Image}
        imgClassName="w-4/5 left-1/2 -translate-x-1/2"
        variant="therapist"
      />

      <TabItem
        className=""
        title="Transparent Earnings"
        description="Therapists receive secure payments, with clear visibilty of their earnings, commission rates and transaction history."
        image={TherapistTab2Image}
        imgClassName="w-4/5 left-1/2 -translate-x-1/2"
        variant="therapist"
      />
      <TabItem
        className=""
        title="Built-in Communication & Feedback"
        description="Therapists can easily communicate with clients before sessions and receive valuable feedback, helping them improve their services and client relationships."
        image={TherapistTab3Image}
        centeredImage
        variant="therapist"
      />
    </div>
  );
};
