import React, { ReactNode } from "react";

interface ContainerProps {
  className?: string;
  children: ReactNode;
  size?: "md" | "lg";
}

export const Container = ({
  className,
  children,
  size = "md",
}: ContainerProps) => {
  return (
    <div
      className={`${
        size === "md" ? "max-w-[1064px]" : "max-w-[1356px]"
      } mx-auto ${className ? className : ""}`}
    >
      {children}
    </div>
  );
};
