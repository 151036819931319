import React, { useState } from "react";
import { ClientTab } from "./components/ClientTab";
import { TherapistTab } from "./components/TherapistTab";
import { TabButton } from "./components/TabButton";
import { Container } from "../../components";

const AppointmentBooking = () => {
  const [tabToShow, setTabToShow] = useState<"client" | "therapist">("client");

  return (
    <section className="bg-[#F1F3FB] px-6 lg:px-12 py-12 lg:py-24" id="Features">
      <Container size="lg">
        <p className="text-3xl lg:text-[56px] lg:leading-[62px] tracking-tighter text-center max-w-[600px] mx-auto mb-6">
          Effective Appointment Booking
        </p>
        <p className="text-center max-w-[670px] mx-auto">
          Welcome to Meind, where we empower therapists by simplifying their
          service processes. Our mission is to maximize their profits while
          ensuring effective client care.
        </p>
        <div className="mt-11 rounded-lg border border-[#888888] bg-white p-2 flex gap-4 items-center w-fit mx-auto">
          <TabButton
            variant="client"
            currentTab={tabToShow}
            onClick={() => setTabToShow("client")}
          />
          <TabButton
            variant="therapist"
            currentTab={tabToShow}
            onClick={() => setTabToShow("therapist")}
          />
        </div>

        {/* Tabs */}
        {tabToShow === "client" ? <ClientTab /> : <TherapistTab />}
      </Container>
    </section>
  );
};

export default AppointmentBooking;
