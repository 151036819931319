import React from "react";
import Benefits from "./Benefits";
import Header from "./Header";
import AppointmentBooking from "./AppointmentBooking";
import HowItWorks from "./HowItWorks";
import Download from "./Download";

export const Home = () => {
  return (
    <>
      <Header />
      <Benefits />
      <AppointmentBooking />
      <HowItWorks />
      <Download />
    </>
  );
};
