import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SlMenu } from "react-icons/sl";
import { MeindLogo } from "../../assets";

export const Navbar = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [willChange, setWillChange] = useState(false);

  const toggleNavbar = () => {
    setWillChange(true);
    setMobileNavOpen((prev) => !prev);

    setTimeout(() => setWillChange(false), 500);
  };

  return (
    <>
      {/* Mobile Nav */}
      <nav className="fixed top-0 left-0 p-6 w-screen flex md:hidden items-center justify-between z-10 backdrop-blur-xl bg-[#FFFFFF66] ">
        <Link className="shrink-0" to="/">
          <img src={MeindLogo} alt="Meind" loading="lazy" />
        </Link>

        <SlMenu size={20} onClick={toggleNavbar} />
      </nav>
      <div
        className={`grid gap-6 fixed left-0 top-10 w-full py-10 px-6 bg-[#FFFFFF66] backdrop-blur-xl z-[5] transform transition-transform duration-500 ease-in-out  ${
          willChange ? "will-change-transform" : ""
        } ${mobileNavOpen ? "translate-y-0" : "-translate-y-[500px]"}`}
      >
        <a href="/#Features" className="py-3">
          Features
        </a>{" "}
        <a href="/#HowItWorks" className="py-3">
          How it works
        </a>
        <div className="flex  items-center gap-6">
          <a
            href="mailto:info@meindtherapy.com"
            className="border-2 border-[#0B1956] text-[#0B1956] px-5 py-2 rounded-lg"
          >
            Contact Us
          </a>
          <Link to="/" className="bg-[#0B1956] border-2 border-[#0B1956] px-5 py-2 rounded-lg text-white">
            Join our mailing list
          </Link>
        </div>
      </div>

      {/* Desktop Nav */}
      <nav className="hidden md:flex justify-between items-center fixed left-1/2 translate-x-[-50%] top-9 w-full max-w-[913px] p-6 rounded-lg backdrop-blur-xl bg-[#FFFFFF66] gradient-border z-10">
        <Link className="shrink-0" to="/">
          <img src={MeindLogo} alt="Meind" loading="lazy" />
        </Link>
        <div className="flex gap-6 items-center">
          <a href="/#Features" className="px-3">
            Features
          </a>{" "}
          <a href="/#HowItWorks" className="px-3">
            How it works
          </a>
        </div>
        <div className="flex justify-center items-center gap-6">
          <a
            href="mailto:info@meindtherapy.com"
            className="border-2 border-[#0B1956] text-[#0B1956] px-5 py-2 rounded-lg button_hover"
          >
            Contact Us
          </a>
          <Link to="/" className="bg-[#0B1956] border-2 border-[#0B1956]  px-5 py-2 rounded-lg text-white button_hover">
            Join our mailing list
          </Link>
        </div>
      </nav>
    </>
  );
};
