import React from "react";
import { TherapySessionImage } from "../../assets";
import { Container } from "../../components";

const Benefits = () => {
  return (
    <section className="py-12 lg:py-24 px-6">
      <Container size="lg">
        <div className="w-fit mx-auto border-2 border-black text-sm px-4 py-3 rounded-[40px] mb-4">
          Benefits
        </div>
        <h3 className="text-4xl text-center max-w-[600px] mx-auto">
          Empowering therapists to{" "}
          <span className="gradient-text">maximize profits and well-being</span>{" "}
        </h3>
        <div className="grid gap-[52px] md:grid-cols-2 mt-14 items-center">
          <img
            src={TherapySessionImage}
            className="w-full lg:h-full rounded-2xl object-cover"
            alt="therapy session"
            loading="lazy"
          />
          <div className="lg:max-w-[285px]">
            <div className="pb-4">
              <h4 className="font-medium text-lg lg:text-xl mb-2">
                User-Friendly Interface
              </h4>
              <p className="mb-8">
                Simple registration and profile creation Social media login
                integration
              </p>
              <div className="gradient-line" />
            </div>
            <div className="pb-4">
              <h4 className="font-medium text-lg lg:text-xl mb-2">
                Therapist Management
              </h4>
              <p className="mb-10">
                Verified therapist profiles Manage availability and client
                onboarding
              </p>
              <div className="gradient-line" />
            </div>
            <div>
              <h4 className="font-medium text-lg lg:text-xl mb-2">
                Booking and Scheduling
              </h4>
              <p>
                Easy appointment scheduling Real-time availability and instant
                booking
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Benefits;
