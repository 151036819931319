import React from "react";
import { ListItem, StarIndicator } from "../../../components";

interface TabItemProps {
  className?: string;
  title: string;
  description: string;
  image: string;
  variant?: "client" | "therapist";
  centeredImage?: boolean;
  imgClassName?: string;
}
export const TabItem = ({
  className,
  title,
  description,
  image,
  variant,
  centeredImage,
  imgClassName,
}: TabItemProps) => {
  return (
    <div
      className={`rounded-lg bg-white grid p-6 gap-6 ${
        className ? className : ""
      }`}
    >
      <div className={`${variant === "client" ? "flex" : "grid"} gap-5`}>
        <StarIndicator className="mt-4" />
        <ListItem title={title} description={description} />
      </div>
      <div
        className={`bg-[#F1F3FB] px-4 pt-4 relative min-h-[350px] ${
          centeredImage ? "flex items-center justify-center" : ""
        } ${variant === "therapist" ? "lg:h-[520px]": ""}`}
      >
        <img
          className={`${
            centeredImage ? "" : "absolute bottom-0 left-0"
          } ${imgClassName}`}
          src={image}
          alt={title}
          loading="lazy"
        />
      </div>
    </div>
  );
};
