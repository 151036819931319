import React from "react";
import { FourPointedStarIcon } from "../assets";

interface StarIndicatorProps {
  className?: string;
}

export const StarIndicator = ({ className }: StarIndicatorProps) => {
  return (
    <div
      className={`bg-gradient-to-r from-[#ADD0E3] via-[#F6C1C9] to-[#FEE5E0] h-[46px] w-[45px] rounded-full flex justify-center items-center shrink-0 ${className}`}
    >
      <img src={FourPointedStarIcon} alt="star" loading="lazy"/>
    </div>
  );
};
