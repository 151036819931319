import React from "react";
import { Container, ListItem } from "../../components";
import { Circle3 } from "../../assets";

const HowItWorks = () => {
  return (
    <section className="bg-[#0B1956] px-6 text-white relative py-10 lg:py-20" id="HowItWorks">
      <Container className="z-[1]">
        <div className="max-w-[572px] mb-9 lg:mb-20 relative z-[2]">
          <div>
            <p className="text-[#ADD0E3]">How it works</p>
          </div>
          <h3 className="text-3xl lg:text-5xl font-bold my-3 ">
            Step by step book a Therapist on Meind
          </h3>
          <p className="text-lg font-normal">
            Get interactive lessons and expert feedback in a supportive
            community.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-6">
          <CardStep
            step={1}
            title="Create Your Profile"
            description="Clients can sign up and explore our diverse  services while therapists set up their profiles to highlight specialties, availability, and experience."
          />
          <CardStep
            step={2}
            title="Find the Perfect Match"
            description="Clients browse therapists based on filters like specialties and availability, while therapists receive booking requests from clients who align with their expertise."
          />
          <CardStep
            step={3}
            title="Book and Get Ready"
            description="Clients book sessions at convenient times and therapists confirm bookings, managing their schedules from their dashboard before securely conducting sessions."
          />
          <CardStep
            step={4}
            title="Manage Progress and Feedback"
            description="Clients can share feedback on their experience, while therapists manage client information and use feedback to continuously improve their practice."
          />
        </div>
      </Container>
      <img
        src={Circle3}
        className="absolute top-0 right-0 w-[200px] h-[183px] md:w-[unset] md:h-[unset]"
        alt="placeholder"
        loading="lazy"
      />
    </section>
  );
};

export default HowItWorks;

const CardStep = ({ step, title, description }: any) => {
  return (
    <div className="border-[0.5px] border-[#D7D4D2] rounded-3xl p-6">
      <div className="w-10 h-10 rounded-full bg-white font-bold text-lg flex justify-center items-center text-black mb-6">
        {step}
      </div>
      <ListItem title={title} description={description} />
    </div>
  );
};
