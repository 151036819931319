import React, { MouseEventHandler } from "react";
import { PiAddressBookDuotone } from "react-icons/pi";

interface TabButtonProps {
  currentTab: "client" | "therapist";
  variant: "client" | "therapist";
  onClick?: MouseEventHandler;
}

export const TabButton = ({ currentTab, variant, onClick }: TabButtonProps) => {
  return (
    <button
      className={`group duration-500 ease-in-out ${
        currentTab === variant
          ? "bg-gradient-to-r from-[#ADD0E3] via-[#F6C1C9] to-[#FEE5E0]"
          : "bg-[#C4C4C4]"
      } p-0.5 rounded-lg hover:bg-gradient-to-r hover:from-[#ADD0E3] hover:via-[#F6C1C9] hover:to-[#FEE5E0]`}
      onClick={onClick}
    >
      <div
        className={`duration-500 ease-in-out bg-white capitalize rounded-md py-2 px-4 flex items-center hover:text-[#0B1956] gap-2 ${
          currentTab === variant ? "text-[#0B1956]" : "text-[#C4C4C4]"
        }`}
      >
        <PiAddressBookDuotone
          size={24}
          className={`ease-in-out group-hover:grayscale-0 ${
            currentTab === variant ? "" : "grayscale"
          }`}
        />
        For {variant}
      </div>
    </button>
  );
};
