import React from "react";
import { Container } from "./Container";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-black text-white text-center pt-8 lg:pt-16 px-6 pb-6 lg:pb-12">
      <Container size="lg">
        <h3 className="text-xl lg:text-3xl mb-8">
          Let&apos;s get started on something great
        </h3>
        {/* <p className="text-[#767676] lg:text-xl max-w-[768px] mx-auto mb-10">
          Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis.{" "}
        </p> */}
        <div className="flex justify-center items-center gap-6 mb-16">
          <a 
            href="mailto:info@meindtherapy.com"
            className="border border-[#A1CEEB] text-[#A1CEEB] px-5 py-3 rounded-lg"
          >
            Contact Us
          </a>
          {/* <Link to="/" className="bg-[#0B1956] px-5 py-3 rounded-lg">
            Sign Up
          </Link> */}
        </div>
        <hr className="border-white" />
        <div className="mt-4 lg:mt-8 flex justify-between items-center text-[10px] lg:text-base">
          <p className="text-left">© {year} Méind Ltd. All rights reserved.</p>
          {/* <div className="flex justify-between items-center gap-4">
            <Link to="/">Terms</Link>
            <Link to="/">Privacy</Link>
            <Link to="/">Cookies</Link>
          </div> */}
        </div>
      </Container>
    </footer>
  );
};
