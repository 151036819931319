import React from "react";

interface ListItemProps {
  className?: string;
  title: string;
  description: string;
}
export const ListItem = ({ className, title, description }: ListItemProps) => {
  return (
    <div className={className ? className : ""}>
      <p className="font-medium text-xl mb-2">{title}</p>
      <p>{description}</p>
    </div>
  );
};
